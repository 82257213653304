<template>
  <div class="flex">
    <LoadingButton variant="outline" @click.native="openModal" class="h-11 mr-2 whitespace-nowrap">
      Import Orders
    </LoadingButton>

    <div
      v-if="showModal"
      tabindex="-1"
      aria-hidden="true"
      class="
        fixed
        top-0
        left-0
        right-0
        w-full
        p-4
        bg-black bg-opacity-50
        overflow-x-hidden overflow-y-auto
        md:inset-0
        md:h-full
        lift
      "
    >
      <div
        class="
          flex flex-column
          bg-white
          rounded-lg
          max-w-lg
          w-100
          min-height-min
          m-auto
          lift
          divide-y divide-gray-200
          relative
          mt-20
        "
      >
        <div class="p-4 flex flex-row justify-between">
          <h5>Import Orders</h5>
          <a href="#" @click="closeModal" class="text-lg font-bold text-black opacity-50 hover:opacity-100 no-underline"
            >x</a
          >
        </div>
        <div class="flex flex-column py-4 px-6 gap-4">
          <Alert v-if="errorMessage" variant="red" icon="exclamation">
            <strong>Failed to upload file</strong>
            <p>{{ errorMessage }}</p>
          </Alert>

          <div class="center-spinner" :class="{ 'shrink-input': isUploading }">
            <LoadingSpinner size="sm" v-if="isUploading" class="mr-2" />
            <b-form-file
              name="input-orders-upload"
              v-model="ordersFile"
              accept=".csv"
              v-validate="{}"
              @input="uploadFile"
              no-drop
            ></b-form-file>
          </div>
          <p>
            Upload a CSV that includes your orders to import here and click the Dry Run button below. We'll check to see
            if there are any formatting errors in it and display them back to you. Unsure what format to follow?
            Download this
            <a href="https://rafflebox-docs.s3.ca-central-1.amazonaws.com/order-import.csv" target="_blank">
              sample CSV
            </a>
            to help format your data.
          </p>
          <p>
            <strong>Please note</strong> that we will only show the first error in your CSV. If you receive an error
            during the dry run check to see if there are others before re-uploading to reduce the number of times you
            need to upload.
          </p>
          <div class="border rounded-lg border-gray-300 text-sm h-40 font-mono p-3 overflow-y-auto">
            <p v-if="validationResponse.length < 1" class="text-sm text-gray-600 font-mono p-2">
              Any errors in your CSV will appear here during the Dry Run.
            </p>
            <div v-else>
              <div v-for="error in validationResponse" :key="error.error" class="mb-4">
                <div class="flex justify-items-stretch">
                  <div class="flex flex-col align-items-start" v-if="!error.isSuccessful">
                    <p class="mb-1 text-red-600" v-if="error.line">Error: Line {{ error.line }}</p>
                    <p class="mb-1 text-red-600">{{ error.line ? error.error : error.message }}</p>
                  </div>
                  <div class="flex flex-col align-items-start" v-else>
                    <p class="mb-1 text-green-600">{{ error.error }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-content-end">
            <LoadingButton
              variant="green-outline"
              size="small"
              :disabled="disableDryRunButton"
              :loading="dryRunLoading"
              class="mr-2 whitespace-nowrap"
              @click.native="importFile(true)"
            >
              Dry Run
            </LoadingButton>
            <LoadingButton
              variant="green"
              size="small"
              :disabled="disableImportButton"
              :loading="importLoading"
              class="whitespace-nowrap"
              @click.native="importFile(false)"
            >
              Import
            </LoadingButton>
          </div>
        </div>
        <div class="px-2 py-3 mt-4 flex flex-row justify-end">
          <LoadingButton
            variant="default"
            @click.native="closeModal"
            class="h-11 mr-4 whitespace-nowrap"
            :disabled="disableCancelButton"
          >
            Cancel
          </LoadingButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/ui/LoadingButton';
import OrderServiceV2 from '@/lib/order-service-v2';
import FileUploadServiceV2 from '@/lib/file-upload-service-v2';
import { formatCurrency } from '@rafflebox-technologies-inc/rafflebox-lib';
import Alert from '@/components/ui/Alert';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

export default {
  components: {
    Alert,
    LoadingButton,
    LoadingSpinner
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      errorMessage: null,
      ordersFile: null,
      disabled: false,
      isUploading: false,
      isImporting: false,
      dryRunSuccessful: false,
      importSuccessful: false,
      s3Url: null,
      validationResponse: []
    };
  },
  computed: {
    dryRunLoading() {
      return this.isImporting && !this.dryRunSuccessful;
    },
    importLoading() {
      return this.isImporting && this.importSuccessful;
    },
    disableDryRunButton() {
      return !this.ordersFile && !this.isUploading;
    },
    disableImportButton() {
      // Enable import button if dry run was successful and disable it after successful import to avoid duplication of orders
      return (this.dryRunSuccessful && !this.importSuccessful ? false : true) || this.isImporting;
    },
    disableCancelButton() {
      return this.isImporting;
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
      document.body.classList.add('overflow-hidden');
    },
    closeModal() {
      this.showModal = false;
      this.resetModal();
      document.body.classList.remove('overflow-hidden');
    },
    resetModal() {
      this.errorMessage = null;
      this.ordersFile = null;
      this.disabled = false;
      this.isUploading = false;
      this.isImporting = false;
      this.dryRunSuccessful = false;
      this.importSuccessful = false;

      this.s3Url = null;
      this.validationResponse = [];
    },
    formatS3Url(url) {
      return url.split('?').shift();
    },
    async createUploadUrl() {
      try {
        this.s3Url = await FileUploadServiceV2.getUploadUrl(this.ordersFile);

        return this.s3Url;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    async uploadFile() {
      // Reset previous details
      this.errorMessage = null;
      this.s3Url = null;
      this.dryRunSuccessful = false;
      this.importSuccessful = false;
      this.validationResponse = [];

      // Safeguard against empty uploads
      if (!this.ordersFile) {
        return;
      }

      try {
        this.isUploading = true;

        // Safeguard against non-CSV uploads
        if (this.ordersFile.type === 'text/csv' || this.ordersFile.type === 'application/vnd.ms-excel') {
          const uploadUrl = await this.createUploadUrl();
          await FileUploadServiceV2.uploadToS3(uploadUrl, this.ordersFile);
        } else {
          this.errorMessage = `
            ${this.ordersFile.type.split('/').pop().toUpperCase()}
            is not a supported file type. Please upload a CSV file.
          `;
        }
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      } finally {
        this.isUploading = false;
      }
    },
    async importFile(dryRun) {
      this.validationResponse = [];
      try {
        this.isImporting = true;

        const params = {
          url: this.formatS3Url(this.s3Url),
          eventId: this.eventId,
          dryRun
        };

        const response = await OrderServiceV2.importOrders(params);

        // Set success responses for dry run and import
        if (dryRun) {
          this.validationResponse.push({
            error: 'Dry run successful, you can now import your orders.',
            line: 0,
            order: {},
            isSuccessful: true
          });
          this.validationResponse.push({
            error: `Total orders: ${response.orders}`,
            line: 1,
            order: {},
            isSuccessful: true
          });
          this.validationResponse.push({
            error: `Jackpot: ${formatCurrency(response.jackpot / 100)}`,
            line: 2,
            order: {},
            isSuccessful: true
          });
          this.dryRunSuccessful = true;
        } else {
          this.validationResponse.push({ error: 'Import successful.', line: 0, order: {}, isSuccessful: true });
          this.importSuccessful = true;
        }
      } catch (error) {
        const errors = error.response.data.errors;

        // If the error is an array of objects with line and order properties, it's a validation error
        if (errors.length > 0 && Object.hasOwn(errors[0], 'dryRunError')) {
          this.validationResponse = errors;
        } else {
          this.errorMessage = errors[0].error;
        }
      } finally {
        this.isImporting = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lift {
  z-index: 1050;
}

.shrink-input {
  .custom-file {
    display: inline-block;
    max-width: calc(100% - 3rem);
  }
}
.custom-file {
  display: inline-block;
}

.center-spinner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
</style>

<template>
  <EventMemberV2>
    <p class="mb-6 mx-2 text-2xl">Event Members</p>

    <div v-if="loading && !errorMessage" class="flex justify-center">
      <LoadingSpinner />
    </div>
    <div v-if="membersExistForEvent && !loading" class="flex flex-wrap items-center justify-between mb-4 mx-2">
      <!-- Search for event members, makes an api call to update table data -->
      <div class="flex flex-row align-center">
        <GenericSearch :value="form.input" @updateSearch="updateSearch" />
        <!-- Create a single event member -->
        <CreateEventMemberModal
          v-if="membersExistForEvent"
          :event="event"
          @created="eventMemberCreated"
          class="ml-3 mr-auto"
        />
      </div>

      <div class="flex xs:mt-3 md:mt-0">
        <EventMembersImportModalV2 :event="event" class="mr-2" />
        <!-- Download a csv file of all event members -->
        <DownloadFile
          v-if="membersExistForEvent"
          :retrieveData="exportEventMembers"
          filename="event-members.csv"
          @error="exportError"
          class="flex"
        >
          <template #default>
            <BaseButton
              variant="secondary-outline"
              :loading="buttonLoading"
              icon="arrow-down-to-line"
              @click.native="downloadReport"
            ></BaseButton>
          </template>
        </DownloadFile>
        <!--
        The split button allows for the following actions:
        - Send links to event members
        - Activate event members
        - Deactivate event members
        - Delete event members
        - Edit an event member
        - Toggle inactive event members on/off
       -->
        <SplitButton
          v-if="membersExistForEvent"
          :primaryLabel="selectedEventMembers.length ? `Send Links (${selectedEventMembers.length})` : 'Send Links'"
          :primaryAction="openModal"
          :secondaryActions="secondaryActions"
          :primaryActionDisabled="!selectedEventMembers.length"
          class="ml-2"
        >
          <template #primary-icon>
            <font-awesome-icon class="text-xl h-4" :icon="['far', 'paper-plane-top']" />
          </template>
          <template #secondaryIcon0>
            <font-awesome-icon class="text-xl h-4" :icon="['fad', 'toggle-on']" />
          </template>
          <template #secondaryIcon1>
            <font-awesome-icon class="text-xl h-4" :icon="['fad', 'toggle-off']" />
          </template>
          <template #secondaryIcon2>
            <font-awesome-icon class="text-xl h-4" :icon="['far', 'trash-can']" />
          </template>
          <template #secondaryIcon3>
            <font-awesome-icon class="text-xl h-4" :icon="['far', 'pen-to-square']" />
          </template>
          <template #secondaryIcon4>
            <font-awesome-icon class="text-xl h-4" :icon="['far', 'eye']" />
          </template>
        </SplitButton>
      </div>
    </div>
    <Alert v-if="errorMessage" class="pt-4 pb-4" variant="red" dismissible fade> {{ errorMessage }} </Alert>
    <!--
      If there are no event members, show the following:
      - Create a single event member dropzone OR import event members

      Otherwise, show the event member table
     -->
    <EventMembersZone v-if="!membersExistForEvent && !loading">
      <CreateEventMemberModal :event="event" @created="eventMemberCreated" class="mr-3" />
      <EventMembersImportModal :event="event" />
    </EventMembersZone>
    <EventMemberTableV2
      v-else
      :data="eventMembers"
      :sort="sortV2"
      :selectedRows="selectedEventMembers"
      :pagination="pagination"
      :eventId="event.id"
      @updateSelectEventMember="updateSelectEventMember"
      :updateCurrentPage="updateCurrentPage"
      :loading="loading"
    />
    <EditEventMemberModalV2 :selectedEventMember="selectedEventMembers[0]" @onEdited="onEditEventMember" />
    <SendEventMemberLinkModalV2 :event="event" :selectedEventMembers="selectedEventMembers" />
  </EventMemberV2>
</template>
<script>
import EventServiceV2 from '@/lib/event-service-v2';
import EventMemberServiceV2 from '@/lib/event-member-service-v2';
import CreateEventMemberModal from '@/components/CreateEventMemberModal.vue';
import EventMembersImportModal from '@/components/EventMembersImportModal.vue';
import SendEventMemberLinkModalV2 from '@/components/modals/SendEventMemberLinkModalV2.vue';
import EventMemberTableV2 from '@/components/EventMemberTableV2.vue';
import EventMembersZone from '@/components/EventMembersZone.vue';
import EventMemberV2 from '@/components/EventMemberV2.vue';
import MessageCenter from '@/lib/message-center';
import DownloadFile from './DownloadFile.vue';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import ReportServiceV2 from '@/lib/report-service-v2';
import GenericSearch from '@/components/GenericSearch.vue';
import EventMembersImportModalV2 from '@/components/EventMembersImportModalV2.vue';
import EditEventMemberModalV2 from '@/components/modals/EditEventMemberModalV2.vue';
import Alert from '@/components/ui/Alert';
import SplitButton from '@/components/rbComponents/SplitButton.vue';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import { downloadFormattedCSV } from '@/lib/download-file';

/**
 * EventMembers.vue is the second version of the In Support Of feature. This new page consolidates the previous two tables into one.
 *
 * From this page a user can:
 * - Create an event member
 * - Import event members
 * - Export event members
 * - Send links to event members
 * - Activate event members
 * - Deactivate event members
 * - Delete event members
 * - Edit an event member
 * - Search for event members
 */

export default {
  props: ['event'],
  components: {
    CreateEventMemberModal,
    EventMembersImportModal,
    DownloadFile,
    EventMembersZone,
    EventMemberTableV2,
    EventMemberV2,
    GenericSearch,
    SendEventMemberLinkModalV2,
    EventMembersImportModalV2,
    EditEventMemberModalV2,
    Alert,
    SplitButton,
    LoadingSpinner,
    BaseButton
  },
  data() {
    return {
      eventMembers: [],
      errorMessage: null,
      successMessage: null,
      successDuration: 0,
      showInactive: false,
      search: '',
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0,
        sortBy: 'eventMemberNumber',
        sortDir: 'asc'
      },
      loading: false,
      buttonLoading: false,
      selectedEventMembers: [],
      organizationId: null,
      icon1: 'secondary-icon-0',
      membersExistForEvent: false
    };
  },
  computed: {
    secondaryActions() {
      return [
        {
          label: this.selectedEventMembers.length ? `Activate (${this.selectedEventMembers.length})` : 'Activate All',
          method: () => {
            this.selectedEventMembers.length ? this.activate() : this.activateAll();
          }
        },
        {
          label: this.selectedEventMembers.length
            ? `Deactivate (${this.selectedEventMembers.length})`
            : 'Deactivate All',
          method: () => {
            this.selectedEventMembers.length ? this.deactivate() : this.deactivateAll();
          }
        },
        {
          label: this.selectedEventMembers.length ? `Delete (${this.selectedEventMembers.length})` : 'Delete All',
          method: () => {
            this.selectedEventMembers.length ? this.delete() : this.deleteAll();
          }
        },
        {
          label: 'Edit Member',
          method: () => {
            this.$root.$emit('bv::show::modal', 'edit-single-event-member-modal');
          },
          disabled: this.selectedEventMembers.length !== 1
        },
        {
          label: this.showInactive ? 'Hide Inactive' : 'Show Inactive',
          method: () => {
            this.showInactive = !this.showInactive;
          }
        }
      ];
    },
    form() {
      return this.$store.getters.getForm;
    }
  },
  async created() {
    const search = this.$route.query.search;
    if (search) {
      this.form.input = search;
    }
  },
  async mounted() {
    const sessionUser = await getAuth().sessionUser();
    this.organizationId = sessionUser.organizationUuid;
    await this.loadData();
  },

  watch: {
    showInactive: function () {
      // Reset the pagination
      this.pagination = {
        page: 0,
        pageSize: 10,
        total: 0,
        sortBy: 'eventMemberNumber',
        sortDir: 'asc'
      };
      this.refreshTable();
    },

    search: function () {
      this.refreshTable();
    },
    'form.input': function () {
      if (this.form.input?.trim().length >= 3) {
        this.refreshTable();
      }
    },
    form: {
      handler() {
        this.refreshTable();
      },
      deep: true
    }
  },
  methods: {
    openModal() {
      this.$root.$emit('bv::show::modal', 'send-event-member-links-modal-v2');
    },

    updateSearch(input) {
      this.$store.commit('setSearchInput', input);
    },
    async exportError(error) {
      this.message = this.parseError(error).message;
    },

    async exportEventMembers() {
      this.buttonLoading = true;
      this.errorMessage = null;

      try {
        const eventMembers = await EventServiceV2.exportEventMembers(this.event.id);

        this.buttonLoading = false;
        return eventMembers;
      } catch (error) {
        this.buttonLoading = false;
        this.errorMessage = this.parseError(error).message;
        throw error;
      }
    },

    displaySuccess(message) {
      this.successMessage = message;
      this.successDuration = 4;
    },
    async sendLink(eventMember) {
      const message = `Are you sure you want to send a link to ${eventMember.name}?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        try {
          await MessageCenter.sendEventMemberLink(this.event.id, eventMember.id);
          this.displaySuccess(`Successfully sent link to ${eventMember.name}.`);
          this.modalShow = false;
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async deactivate() {
      const message = `Are you sure you want to deactivate ${this.selectedEventMembers.length} event members?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);
      const eventMemberIds = this.selectedEventMembers;

      if (confirm) {
        this.errorMessage = null;

        try {
          await EventServiceV2.updateMultipleEventMembers({
            eventId: this.event.id,
            active: false,
            eventMemberIds
          });

          // if the user deactivates selected event members, the table will show the last page of active event members
          if (!this.showInactive) {
            this.pagination.page =
              eventMemberIds.length === this.eventMembers.length ? this.pagination.page - 1 : this.pagination.page;
          }

          this.pagination = {
            page: this.pagination.page,
            pageSize: 10,
            total: 0,
            sortBy: 'eventMemberNumber',
            sortDir: 'asc'
          };

          this.refreshTable();
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async deactivateAll() {
      const message = `Are you sure you want to deactivate all event members?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);
      if (confirm) {
        this.errorMessage = null;

        try {
          await EventServiceV2.updateMultipleEventMembers({ eventId: this.event.id, active: false });
          this.refreshTable();
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async delete() {
      const message = `Are you sure you want to delete ${this.selectedEventMembers.length} event members?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      const eventMemberIds = this.selectedEventMembers;

      if (confirm) {
        this.errorMessage = null;

        try {
          await EventMemberServiceV2.deleteEventMember(this.event.id, { eventMemberIds });
          this.refreshTable();
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async deleteAll() {
      const message = `Are you sure you want to delete all event members?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        this.errorMessage = null;

        try {
          await EventMemberServiceV2.deleteEventMember(this.event.id);
          this.membersExistForEvent = false;
          this.refreshTable();
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async activate() {
      const message = `Are you sure you want to activate ${this.selectedEventMembers.length} event members?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);
      const eventMemberIds = this.selectedEventMembers;

      if (confirm) {
        this.errorMessage = null;

        try {
          await EventServiceV2.updateMultipleEventMembers({
            eventId: this.event.id,
            active: true,
            eventMemberIds
          });

          this.refreshTable();
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async activateAll() {
      const message = `Are you sure you want to activate all event members?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        this.errorMessage = null;

        try {
          await EventServiceV2.updateMultipleEventMembers({ eventId: this.event.id, active: true });
          this.refreshTable();
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async confirmDeactivate(row) {
      const eventMember = row;
      const message = `Are you sure you want to deactivate ${eventMember.name}?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        this.deactivateEventMember(eventMember);
      }
    },
    async confirmDelete(row) {
      const eventMember = row;
      const message = `Are you sure you want to permanently delete ${eventMember.name}?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        this.deleteEventMember(eventMember);
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return;
      }

      if (!item.active) {
        return 'inactive-member';
      }
    },
    onEditEventMember() {
      this.refreshTable();
    },
    async refreshTable() {
      this.errorMessage = null;
      await this.loadData();

      this.$root.$emit('bv::refresh::table', 'event-members-table');
    },
    async deactivateEventMember(eventMember) {
      this.errorMessage = null;

      try {
        await EventServiceV2.updateEventMember(eventMember.id, { active: false });
        this.displaySuccess(`Deactivated ${eventMember.name} successfully.`);
        this.refreshTable();
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },

    async deleteEventMember(eventMember) {
      this.errorMessage = null;

      try {
        await EventServiceV2.deleteEventMember(eventMember.id);
        this.displaySuccess(`Deleted ${eventMember.name} successfully.`);
        this.refreshTable();
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    eventMemberCreated() {
      this.refreshTable();
    },
    async sortV2(sortBy, sortDir) {
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;

      await this.loadData();
    },
    updateSelectEventMember(rows) {
      this.selectedEventMembers = rows;
    },
    async updateCurrentPage(page) {
      this.pagination.page = page;
      await this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;

        const search = this.form.input.trim().length > 0 ? this.form.input.trim() : '';

        const params = {
          eventId: this.event.id,
          organizationId: this.organizationId,
          active: !this.showInactive,
          page: this.pagination.page,
          pageSize: 10,
          sortBy: this.pagination.sortBy || undefined,
          sortDir: this.pagination.sortDir.toLocaleUpperCase(),
          search
        };

        if (search) {
          params.page = 0;
        }
        const data = await ReportServiceV2.retrieveEventMemberSalesReport(params);

        this.pagination.total = data.total;

        // Disable selection for "Entire Group" row
        data.data.forEach((eventMember) => {
          eventMember.disableSelection = eventMember.eventMemberName === 'Entire Group';
        });

        this.eventMembers = data.data.filter((eventMember) => {
          return eventMember.eventMemberName === 'Entire Group' || eventMember.active === !this.showInactive;
        });

        this.loading = false;
        if (this.eventMembers.length > 0) {
          this.membersExistForEvent = true;
        } else if (!this.showInactive) {
          // If we aren't showing inactive members and we found 0, lets check if any exist
          const response = await ReportServiceV2.retrieveEventMemberSalesReport({
            ...params,
            active: false,
            page: 0,
            pageSize: 1
          });

          // If we find more than 1 inactive, OR 1 and the result is Entire Group
          if (response.total > 1 || (response.total === 1 && response.data[0].eventMemberName !== 'Entire Group')) {
            this.membersExistForEvent = true;
            this.showInactive = true;
          }
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.loading = false;
      }
    },
    async downloadReport() {
      this.buttonLoading = true;
      try {
        const search = this.form.input.trim().length > 0 ? this.form.input.trim() : '';
        const data = await ReportServiceV2.retrieveEventMemberSalesReport({
          eventId: this.event.id,
          search,
          contentType: 'text/csv',
          pageSize: 1000000
        });

        downloadFormattedCSV(`event_member_sales_${Date.now()}.csv`, data);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.buttonLoading = false;
      }
    }
  }
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.status-column {
  width: 3em;
}
.alert-container {
  height: 2em;
}
</style>
